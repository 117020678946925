import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Col, Offcanvas, Row } from "react-bootstrap";
import { isMobileNumberValid } from "../../../utils/helper";
import swal from "sweetalert";
import {
  CHECK_DUPLICATE_ENTRIES,
  FETCH_ALL_MERCHANT_VALIDATIONS,
  FETCH_MERCHANT_FOR_MASTERS_FORMS,
  GET_ALL_MERCHANT_MASTER,
  SAVE_MERCHANT_PAYOUT_MASTER,
} from "../../../utils/constant";
import useApiServices from "../../../utils/useApiServices";
import { Refresh } from "@mui/icons-material";

const PayoutMasterForm = ({ onHide, show, setRefresh, refresh }) => {
  const [merchantData, setMerchantData] = useState([]);
  const [transferType, setTransferType] = useState("");
  const [isIfscError, setIsIfscError] = useState(false);
  const [mid, setMid] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [data, setData] = useState({});
  const [merchantMasterData, setMerchantMasterData] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const { apiCallFnforPost, apiCallFnforGet } = useApiServices();
  const [formData, setFormData] = useState({
    mid: "",
    merchantName: "",
  });
  const offcanvasStyle = {
    width: "70vw", // Set width to 70vw
  };
  const [formDataInputs, setFormDataInputs] = useState({
    accountsModuleSecrete: "",

    clientIds: "",
    clientSecrete: "",
    ifsc: "",

    masterAliusId: "",
    masterVirtualAccount: "",
    mvaCustomerId: "",
    mvaMobileNumber: "",
    password: "",
    paymentModuleSecrete: "",
    userName: "",
    providerSecrete: "",
  });

  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    setSelectedCompany(selectedCompany);

    const selectedMerchant = merchantData.find(
      (merchant) => merchant.merchantName === selectedCompany
    );

    if (selectedMerchant) {
      setMid(selectedMerchant.mid);
      setFormData({
        mid: selectedMerchant.mid || "",
        merchantName: selectedMerchant.merchantName || "",
      });
    } else {
      setMid("");
      setFormData({
        mid: "",
        merchantName: "",
      });
    }
  };

  function stopSpecialCharacterInput(input) {
    // Define a regular expression for alphanumeric characters
    const alphanumericRegex = /^[a-zA-Z0-9]+$/;

    // Test the input against the regular expression
    return alphanumericRegex.test(input);
  }

  function setNumericInput(input) {
    // Define a regular expression for numeric characters
    const numericRegex = /^[0-9]+$/;

    // Test the input against the regular expression
    return numericRegex.test(input) ? input : "";
  }

  function setValidIFSCCode(input) {
    // Define a regular expression for a valid IFSC code
    const ifscRegex = /^[A-Z]{4}[0][A-Z0-9]{6}$/;

    // Test the input against the regular expression
    return ifscRegex.test(input) ? input : "";
  }

  const HandleIFSC = (e) => {
    if (e.target.name === "ifsc") {
      const ifscRegex = new RegExp(/^[a-zA-Z]{4}0[A-Z0-9]{6}$/);
      const isValidIfsc = ifscRegex.test(e.target.value);
      if (!isValidIfsc) {
        setIsIfscError(true);
        document.getElementById("ifscErr").classList.remove("d-none");

        return;
      } else {
        setIsIfscError(false);
        document.getElementById("ifscErr").classList.add("d-none");
      }
      setIsIfscError(false);
    }
  };

  const handleformDataInputs = (e) => {
    console.log(e.target.name, e.target.value);

    if (!e.target.value.length) {
      setFormDataInputs({
        ...formDataInputs,
        [e.target.name]: e.target.value,
      });
    }

    if (
      e.target.name === "accountsModuleSecrete" ||
      e.target.name === "paymentModuleSecrete" ||
      e.target.name === "masterVirtualAccount" ||
      e.target.name === "providerSecrete" ||
      e.target.name === "ifsc" ||
      e.target.name === "clientSecrete"
    ) {
      if (stopSpecialCharacterInput(e.target.value)) {
        console.log(stopSpecialCharacterInput(""));
        setFormDataInputs({
          ...formDataInputs,
          [e.target.name]: e.target.value,
        });
      } else {
        return;
      }
    } else if (
      e.target.name === "mvaMobileNumber" ||
      e.target.name === "mvaCustomerId"
    ) {
      if (setNumericInput(e.target.value)) {
        setFormDataInputs({
          ...formDataInputs,
          [e.target.name]: e.target.value === "" ? "" : e.target.value,
        });
      } else {
        return;
      }
    } else {
      setFormDataInputs({
        ...formDataInputs,
        [e.target.name]: e.target.value === "" ? "" : e.target.value,
      });
    }
  };
  useEffect(() => {
    const checkDuplicateOnTransferTypeChange = async () => {
      if (mid && transferType) {
        const isDuplicate = await checkDuplicateEntry();
        if (isDuplicate) {
          swal({
            title: "Duplicate Entry!",
            text: "Merchant is already associated with this transfer type",
            icon: "warning",
          });
        }
      }
    };

    checkDuplicateOnTransferTypeChange();
  }, [mid, transferType]);

  const checkDuplicateEntry = async () => {
    try {
      const response = await apiCallFnforPost(CHECK_DUPLICATE_ENTRIES, {
        mid: mid,
        transerType: transferType.toUpperCase(),
      });
      console.log("Response from checkDuplicateEntry:", response.data);
      if (
        response.statusCode === 200 &&
        response.status ===
          "Merchant is already associated with this transfer type"
      ) {
        console.log("Duplicate entry detected");
        swal({
          title: "Duplicate Entry!",
          text: response.status,
          icon: "warning",
        });
        return true;
      } else if (
        response.statusCode === 200 &&
        response.status === "Merchant is not associated with this transfer type"
      ) {
        return false;
      } else {
        swal({
          title: "Not Associated!",
          text: response.status,
          icon: "info",
        });
      }
    } catch (error) {
      console.error("Error checking entry:", error);
    }
    console.log("Default case - No matching condition found");
    return false;
  };

  const handelDataChange = (e) => {
    if (e.target.name === "vehicle") {
      const selectedOption = e.target.options[e.target.selectedIndex];
      const master = selectedOption.getAttribute("data-master");
      const masterId = selectedOption.getAttribute("data-masterId");
      console.log(masterId);
      if (e.target.value === "") {
        setData({
          ...data,
          [e.target.name]: "",
          merchantMasterName: "",
          merchantMasterId: "",
        });
        return;
      }
      setData({
        ...data,
        [e.target.name]: e.target.value,
        merchantMasterName: master,
        merchantMasterId: masterId,
      });
      console.log(data);
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }

    console.log(data);
  };

  const handleSubmit = async (e) => {
    const isDuplicateEntry = await checkDuplicateEntry();

    if (isDuplicateEntry) {
      console.log("Duplicate entry found. Not saving the payout.");
      return;
    } else {
      if (formData.merchantName === "") {
        swal({
          title: "Alert!",
          text: "Please Select Merchant",
          icon: "error",
        });
        return;
      }

      if (isIfscError) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid IFSC Code",
          icon: "error",
        });
        return;
      }

      if (transferType === "") {
        swal({
          title: "Alert!",
          text: "Please Select Transfer Type",
          icon: "error",
        });
        return;
      }

      if (formDataInputs.accountsModuleSecrete === "") {
        swal({
          title: "Alert!",
          text: "Please Enter Accounts Module Secret",
          icon: "error",
        });
        return;
      }
      if (formDataInputs.clientIds === "") {
        swal({
          title: "Alert!",
          text: "Please Enter Client Id",
          icon: "error",
        });
        return;
      }
      if (formDataInputs.clientSecrete === "") {
        swal({
          title: "Alert!",
          text: "Please Enter Client Secret",
          icon: "error",
        });
        return;
      }
      if (formDataInputs.ifsc === "") {
        swal({
          title: "Alert!",
          text: "Please Enter IFSC Code",
          icon: "error",
        });
        return;
      }
      if (formDataInputs.mvaCustomerId === "") {
        swal({
          title: "Alert!",
          text: "Please Enter MVA Customer Id",
          icon: "error",
        });
        return;
      }
      if (formDataInputs.masterAliusId === "") {
        swal({
          title: "Alert!",
          text: "Please Enter Master Alius Id",
          icon: "error",
        });
        return;
      }
      if (formDataInputs.masterVirtualAccount === "") {
        swal({
          title: "Alert!",
          text: "Please Enter Master Virtual Account",
          icon: "error",
        });
        return;
      }

      if (formDataInputs.userName === "") {
        swal({
          title: "Alert!",
          text: "Please Enter  User Name",
          icon: "error",
        });
        return;
      }

      if (formDataInputs.providerSecrete === "") {
        swal({
          title: "Alert!",
          text: "Please Enter Provider Secret",
          icon: "error",
        });
        return;
      }
      if (formDataInputs.paymentModuleSecrete === "") {
        swal({
          title: "Alert!",
          text: "Please Enter Payment Module Secret",
          icon: "error",
        });
        return;
      }

      if (formDataInputs.password === "") {
        swal({
          title: "Alert!",
          text: "Please Enter Password",
          icon: "error",
        });
        return;
      }
      // if (formDataInputs.mvaMobileNumber === "") {
      //   swal({
      //     title: "Alert!",
      //     text: "Please Enter MVA Mobile Number",
      //     icon: "error",
      //   });
      //   return;
      // }

      if (!isMobileNumberValid(formDataInputs.mvaMobileNumber)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid Mobile Number",
          icon: "error",
        }).then(() => {
          setFormDataInputs({ ...formDataInputs, mvaMobileNumber: "" });
        });
        return;
      }

      if (data.vehicle === "") {
        swal({
          title: "Alert!",
          text: "Please Select Vehicle Master",
          icon: "error",
        });
        return;
      }

      const payload = {
        ...formDataInputs,
        mid,
        transferType,
        vehichleMasterId: data.vehicle,
        masterMerchantId: data.merchantMasterId,
      };

      e.preventDefault();
      const swalConfirm = await swal({
        title: "Are you sure?",
        text: "Do you want to submit the application?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });
      if (swalConfirm) {
        // setShowLoader(true);
        apiCallFnforPost(SAVE_MERCHANT_PAYOUT_MASTER, payload)
          .then((response) => {
            console.log(response);

            if (response.statusCode === 200) {
              swal({
                title: "Success!",
                text: response.message,
                icon: "success",
              });
              setFormData({
                mid: "",
                merchantName: "",
              });
              setFormDataInputs({
                accountsModuleSecrete: "",
                clientIds: "",
                clientSecrete: "",
                ifsc: "",
                masterAliusId: "",
                masterVirtualAccount: "",
                mvaCustomerId: "",
                mvaMobileNumber: "",
                password: "",
                paymentModuleSecrete: "",
                userName: "",
                providerSecrete: "",
              });
              setData({
                merchantMaster: "",
                vehicle: "",

                merchantMasterName: "",
                merchantMasterId: "",
              });

              setSelectedCompany("");
              onHide();
              setRefresh(!refresh);
            } else {
              swal({
                title: "Alert!",
                text: response.message,
                icon: "error",
              });
            }
            // setShowLoader(false);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            // setShowLoader(false);
          });
      }
    }
  };

  const getAllMerchantForApiTest = async () => {
    // setShowLoader(true);
    let res = await apiCallFnforGet(FETCH_ALL_MERCHANT_VALIDATIONS);
    // setShowLoader(false);
    setMerchantData(res?.data);
  };

  useState(() => {
    getAllMerchantForApiTest();
  }, []);

  useEffect(() => {}, [transferType]);

  const getAllVehicle = async () => {
    try {
      const payload = {
        serviceType: "PAYOUT",
      };
      const response = await apiCallFnforPost(
        FETCH_MERCHANT_FOR_MASTERS_FORMS,
        payload
      ); // Add the await keyword here

      if (response.statusCode === 200) {
        setVehicleData(response?.data);
      } else {
        setVehicleData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllMerchantMaster = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_MERCHANT_MASTER); // Add the await keyword here
      if (response?.statusCode === 200) {
        setMerchantMasterData(response?.data);
      } else {
        setMerchantMasterData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllVehicle();
    getAllMerchantMaster();
  }, []);
  const handleClose = () => {
    setFormData({
      mid: "",
      merchantName: "",
    });
    setFormDataInputs({
      accountsModuleSecrete: "",
      clientIds: "",
      clientSecrete: "",
      ifsc: "",
      masterAliusId: "",
      masterVirtualAccount: "",
      mvaCustomerId: "",
      mvaMobileNumber: "",
      password: "",
      paymentModuleSecrete: "",
      userName: "",
      providerSecrete: "",
    });
    setData({
      merchantMaster: "",
      vehicle: "",

      merchantMasterName: "",
      merchantMasterId: "",
    });

    setSelectedCompany("");
    onHide();
    setRefresh(!refresh);
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        style={offcanvasStyle}
        backdrop="static"
        keyboard={false}
      >
        <div
          className="d-flex justify-content-start align-items-center mt-3"
          style={{ marginLeft: "20px" }}
        >
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <h5
            class="offcanvas-title fs-2 text-dark fw-bold"
            id="offcanvasLabel"
          >
            Payout Master Form
          </h5>
        </div>

        <Offcanvas.Body>
          <div className="container-fluid">
            <div class="row">
              <div class="col-12">
                <div id="" className="form-wizard order-create">
                  <div id="" className="tab-pane" role="tabpanel">
                    <Row>
                      <Col xs={16} lg={12} md={12}>
                        <div className="d-flex justify-content-evenly gap-4">
                          <div className="form-group w-50">
                            <label className="mb-1 text-dark fs-4">
                              Select A Merchant
                            </label>
                            <select
                              id="companyDropdown"
                              className="form-control"
                              onChange={handleCompanyChange}
                              value={selectedCompany}
                            >
                              <option value="">Select a Merchant</option>
                              {merchantData?.map((merchant) => (
                                <option
                                  key={merchant.mid}
                                  value={merchant.merchantName}
                                >
                                  {merchant.merchantName}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="form-group w-50">
                            <label className="mb-1 text-dark fs-4">
                              Merchant MID
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="email"
                              placeholder="Merchant MID"
                              value={formData.mid}
                              readOnly
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row className="d-flex justify-content-center">
                      <div className="w-50 d-flex justify-content-evenly gap-5 mt-5   ">
                        <div
                          className={`${
                            transferType === "imps"
                              ? "rounded-5 text-light"
                              : ""
                          }d-flex justify-content-evenly align-items-center gap-3 p-3 py-2`}
                        >
                          <div class="checkbox-wrapper-27">
                            <label class="checkbox">
                              <input
                                type="checkbox"
                                name="bankDetails"
                                checked={transferType === "imps" ? true : false}
                                onChange={() => setTransferType("imps")}
                              ></input>
                              <span class="checkbox__icon"></span>
                              IMPS
                            </label>
                          </div>
                        </div>
                        <div
                          className={`${
                            transferType === "upi"
                              ? "rounded-5 text-light  "
                              : ""
                          }d-flex justify-content-evenly align-items-center gap-3 p-3 py-2`}
                        >
                          <div class="checkbox-wrapper-27">
                            <label class="checkbox">
                              <input
                                type="checkbox"
                                name="bankDetails"
                                checked={transferType === "upi" ? true : false}
                                onChange={() => setTransferType("upi")}
                              ></input>
                              <span class="checkbox__icon"></span>
                              UPI
                            </label>
                                      
                          </div>
                        </div>
                      </div>
                    </Row>
                    <Row className="px-4">
                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label className="mb-1 text-dark fs-4">
                            Select A Vehicle
                          </label>
                          <select
                            id="companyDropdown"
                            className="form-control"
                            onChange={handelDataChange}
                            name="vehicle"
                            value={data.vehicle}
                          >
                            <option value="">Select a Vehicle</option>
                            {vehicleData?.map((vehicle) => (
                              <option
                                key={vehicle.id}
                                data-master={vehicle.masterMerchantName}
                                data-masterId={vehicle.masterMerchantId}
                                value={vehicle.id}
                              >
                                {vehicle.vehicleName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label className="mb-1 text-dark fs-4">
                            Select Master Merchant
                          </label>
                          <input
                            id="companyDropdown"
                            className="form-control"
                            onChange={handelDataChange}
                            readOnly
                            name="merchantMaster"
                            value={data.merchantMasterName}
                          />
                        </div>
                      </Col>
                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label className="mb-1 text-dark fs-4">
                            Account Module Secret
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="d-flex gap-2">
                            <input
                              className="form-control"
                              name="accountsModuleSecrete"
                              value={formDataInputs.accountsModuleSecrete}
                              onChange={handleformDataInputs}
                            />
                          </div>
                        </div>
                        {/* ./form-group */}
                      </Col>

                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label className="mb-1 text-dark fs-4">
                            Client Id
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="d-flex gap-2">
                            <input
                              className="form-control"
                              name="clientIds"
                              value={formDataInputs.clientIds}
                              onChange={handleformDataInputs}
                            />
                          </div>
                        </div>
                        {/* ./form-group */}
                      </Col>
                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label className="mb-1 text-dark fs-4">
                            Client Secret
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="d-flex gap-2">
                            <input
                              className="form-control"
                              name="clientSecrete"
                              value={formDataInputs.clientSecrete}
                              onChange={handleformDataInputs}
                            />
                          </div>
                        </div>
                        {/* ./form-group */}
                      </Col>
                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label className="mb-1 text-dark fs-4">
                            IFSC
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="d-flex gap-2">
                            <input
                              className="form-control"
                              name="ifsc"
                              value={formDataInputs.ifsc}
                              onChange={handleformDataInputs}
                              onBlur={HandleIFSC}
                            />
                          </div>
                          <span id="ifscErr" className=" text-danger d-none">
                            *Please Enter Valid IFSC!{" "}
                          </span>
                        </div>
                      </Col>

                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label className="mb-1 text-dark fs-4">
                            MVA Customer Id
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="d-flex gap-2">
                            <input
                              className="form-control"
                              name="mvaCustomerId"
                              value={formDataInputs.mvaCustomerId}
                              onChange={handleformDataInputs}
                            />
                          </div>
                        </div>
                        {/* ./form-group */}
                      </Col>
                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label className="mb-1 text-dark fs-4">
                            Master Alius Id
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="d-flex gap-2">
                            <input
                              className="form-control"
                              name="masterAliusId"
                              value={formDataInputs.masterAliusId}
                              onChange={handleformDataInputs}
                            />
                          </div>
                        </div>
                        {/* ./form-group */}
                      </Col>
                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label className="mb-1 text-dark fs-4">
                            Master Virtual Account
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="d-flex gap-2">
                            <input
                              className="form-control"
                              name="masterVirtualAccount"
                              value={formDataInputs.masterVirtualAccount}
                              onChange={handleformDataInputs}
                            />
                          </div>
                        </div>
                        {/* ./form-group */}
                      </Col>

                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label className="mb-1 text-dark fs-4">
                            User Name
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="d-flex gap-2">
                            <input
                              className="form-control"
                              name="userName"
                              value={formDataInputs.userName}
                              onChange={handleformDataInputs}
                            />
                          </div>
                        </div>
                        {/* ./form-group */}
                      </Col>
                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label className="mb-1 text-dark fs-4">
                            Provider Secret
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="d-flex gap-2">
                            <input
                              className="form-control"
                              name="providerSecrete"
                              value={formDataInputs.providerSecrete}
                              onChange={handleformDataInputs}
                            />
                          </div>
                        </div>
                        {/* ./form-group */}
                      </Col>
                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label className="mb-1 text-dark fs-4">
                            Payment Module Secret
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="d-flex gap-2">
                            <input
                              className="form-control"
                              name="paymentModuleSecrete"
                              value={formDataInputs.paymentModuleSecrete}
                              onChange={handleformDataInputs}
                            />
                          </div>
                        </div>
                        {/* ./form-group */}
                      </Col>
                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label className="mb-1 text-dark fs-4">
                            Password
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="d-flex gap-2">
                            <input
                              className="form-control"
                              name="password"
                              value={formDataInputs.password}
                              onChange={handleformDataInputs}
                            />
                          </div>
                        </div>
                        {/* ./form-group */}
                      </Col>
                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label className="mb-1 text-dark fs-4">
                            MVA Mobile Number
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="d-flex gap-2">
                            <input
                              className="form-control"
                              maxLength={10}
                              minLength={10}
                              name="mvaMobileNumber"
                              value={formDataInputs.mvaMobileNumber}
                              onChange={handleformDataInputs}
                              pattern="[0-9]{10}"
                              title="Please enter a 10-digit mobile number"
                            />
                          </div>
                        </div>
                        {/* ./form-group */}
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-end mt-4">
                      <button
                        className="badge badge-rounded badge-primary px-3 py-3 mx-2"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
export default PayoutMasterForm;
